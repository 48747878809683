import type { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useErrorPage, useStaticPage } from '@mwl/core-lib';
import { defaultLocale } from '@/constants';
const ErrorLayout = dynamic(() => import('@/Layouts/Error/ErrorLayout').then(mod => mod.ErrorLayout));
const Layout = dynamic(() => import('@/Layouts/Layout/Layout').then(mod => mod.Layout));
const Custom404: NextPage = () => {
  const {
    t
  } = useTranslation('error');
  useStaticPage();
  useErrorPage();
  return <Layout title={t('message', 'Not found')} withFooter={false} withHeader={false} coverContent data-sentry-element="Layout" data-sentry-component="Custom404" data-sentry-source-file="404.tsx">
      <ErrorLayout code={404} message={t('message', 'Page not found...')} data-sentry-element="ErrorLayout" data-sentry-source-file="404.tsx" />
    </Layout>;
};
export const getStaticProps: GetStaticProps = async ({
  locale = defaultLocale,
  locales
}) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['error'], null, locales))
    }
  };
};
export default Custom404;